<template>
  <k-field-group language-prefix="role.fields">
    <k-form-dialog :panels="panels" contentCols="10" v-bind="$attrs" v-on="$listeners">
      <template #panel.0>
        <v-row class="fill-height" justify="start">
          <v-col cols="12">
            <form-sheet class="fill-height">
              <KTextField v-model="values.name" field="name" required/>
              <v-simple-table v-for="group in values.permissions" :key="group.groupName" class="mt-16" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class=" text-subtitle-1 secondary--text text--darken-4 font-weight-bold pb-2">
                        {{ $t(`role.permissionGroups.${group.groupName}.groupName`) }}
                      </th>
                      <th v-for="operationLabel in [ $t('role.operations.create'), $t('role.operations.edit'), $t('role.operations.delete'), $t('role.operations.show'), ]"
                          :key="operationLabel"
                          class="checkbox-cell text-center pb-2">{{ operationLabel }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="permissions in group.groupPermissions" :key="permissions.name">
                      <td>{{
                          /**/
                          $t(`role.permissionGroups.${group.groupName}.permissions.${permissions.name}`)
                        }}
                      </td>
                      <td v-for="operation in [ 'create', 'update', 'delete', 'read' ]"
                          :key="operation"
                          class="checkbox-cell">
                        <div class="checkbox-wrapper d-flex justify-center py-4">
                          <v-checkbox v-if=" permissions.operations .map((operation) => operation.name.split('.').pop() ) .includes(operation)"
                                      v-model=" permissions.operations.find( (e) => e.name.split('.').pop() === operation ).granted"
                                      class="input-checkbox"
                                      hide-details></v-checkbox>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>
<script>import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';

export default {
  name: 'RoleForm',
  components: {
    FormSheet,
    KFieldGroup,
    KFormDialog,
    KTextField,
  },
  props: { values: { type: Object } },
  computed: {
    panels() {
      return [{ name: this.$t('global.defaultTabName') }];
    },
  },
};</script>
<style scoped>

.checkbox-cell {
  width: 68px;
}

th.checkbox-cell {
  vertical-align: bottom;
}

.checkbox-wrapper {
  margin-right: -8px;
}

.input-checkbox {
  margin-top: -6px;
}</style>