<template>
  <div>
    <k-crud-layout :search.sync="searchQuery" >
      <template #header>{{ $t('role.menuTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile @click="openCreateDialog">{{ $t('role.create') }}</v-btn>
        <RoleForm v-if="isLoaded"
                  v-model="createDialog"
                  :request="create"
                  :title="$t('role.create')"
                  :values="createFormValues"
                  @change="$refs.table.reload()"/>
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      language-prefix="role.crudHeaders"
                      resource="role">
          <template #actions="{item}">
            <v-btn icon>
              <v-icon @click="openUpdateDialog(item)">$edit</v-icon>
            </v-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-layout>
    <RoleForm v-model="updateDialog"
              :request="update"
              :title="$t('role.update')"
              :values="updateFormValues"
              is-update-form
              @change="$refs.table.reload()"/>
  </div>
</template>
<script lang="js">import Role from '@/application/models/Role.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import { EventBus } from '@/application/EventBus.js';
import { mapGetters } from 'vuex';
import RoleForm from '@/modules/roles/views/RoleForm.vue';
import { getAllPermissions, create, update, index, show } from '@/modules/roles/api/role.js';

export default {
  name: 'RolesList',
  mixins: [querySyncMixin],
  components: {
    KCrudTable,
    KCrudLayout,
    RoleForm,
  },
  data() {
    return {
      filters: {},
      createDialog: false,
      updateDialog: false,
      createFormValues: new Role(),
      updateFormValues: new Role(),
      searchQuery: '',
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }), ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [{
        value: 'name',
        language: 'name',
      }];
    },
  },
  created() {
    this.getAllPermissions();
  },
  methods: {
    reloadTable() {
      this.$refs.table.reload();
    },
    indexRequest(...args) {
      return index(this.clientId, ...args);
    },
    async create() {
      await create(this.clientId, this.createFormValues.mapForRequest());
      this.createDialog = false;
      this.reloadTable();
    },
    async update() {
      await update(this.clientId, this.updateFormValues.mapForRequest());
      this.updateDialog = false;
      this.reloadTable();
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    async openUpdateDialog(role) {
      const response = await show(this.clientId, role.id);
      this.updateDialog = true;
      this.updateFormValues = this.updateFormValues.mapResponse(response.data.data);
    },
    async getAllPermissions() {
      const response = await getAllPermissions();
      this.createFormValues.permissions = response.data.data;
      this.updateFormValues.permissions = response.data.data;
      this.isLoaded = true;
    },
    deleteRole(role) {
      EventBus.$emit('confirm', {
        title: this.$t('role.actions.delete.confirmationTitle', { role: role.name }),
        body: this.$t('role.actions.delete.confirmationBody', { role: role.name }),
        confirmCallback: async () => {
          try {/*@TODO Add api call to delete role*/
            EventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('role.actions.delete.success'),
            });
          } catch (e) {
            EventBus.$emit('snackbar', {
              color: 'red',
              text: this.$t('role.actions.delete.noSuccess'),
            });
          }
        },
      });
    },
  },
};</script>
