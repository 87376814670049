import Model from './model';

export default class Role extends Model {
  id = 0;
  name = '';
  manageableRoleIds = [];
  permissions = [];

  mapForRequest() {
    return {
      ...this,
      id: this.id,
      name: this.name,
      manageableRoleIds: this.manageableRoleIds.length === 0 ? null : this.manageableRoleIds,
      permissions: this.mapPermissions(this.permissions),
    };
  }

  mapPermissions(permissions) {
    const mappedPermissions = [];
    permissions.map((group) => {
      group.groupPermissions.map((permission) => {
        permission.operations.map((operation) => {
          mappedPermissions.push({
            id: operation.id,
            granted: !!operation.granted,
          });
        });
      });
    });
    return mappedPermissions;
  }

  mapResponse(data) {
    this.permissions.forEach((group) => {
      group.groupPermissions.forEach((permission) => {
        permission.operations.forEach((operation) => {
          if (data.grantedPermissionIds.includes(operation.id)) {
            operation.granted = true;
          }
        });
      });
    });
    return super.mapResponse({ ...data });
  }
}
